@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 90%; /* Default width for smaller screens */
  min-height: 100px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding:1.5em;
  border-radius: 0.5em;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .modal-content {
      width: 60%;
  }
}